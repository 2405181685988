<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card-body>
         
          <b-row>
            <b-table
              striped
              hover
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPage"
              :items="history_list"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(document)="data">
                <b-link :href="fileUrl + data.item.document" target="_blank">
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                    title="View All Documents"
                /></b-link>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50 text-danger"
                  title="Delete Document"
                  @click="deleteFile(data.item.id)"
                />
              </template>
            </b-table>
          </b-row>
          <!--  -->
          <div></div>
        </b-card-body>
      </b-col>
    </b-row>
  </div>
</template>
            
            <script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormTimepicker,
  BFormDatepicker,
  BDropdownItem,
  BDropdown,
  BLink,
} from "bootstrap-vue";
import { required, min, confirmed } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { serverUri } from "@/config";
import router from "@/router";
import { getUserData } from "@/auth/utils";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BInputGroup,
    BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    flatPickr,
    DateRangePicker,
    vSelect,
    BDropdownItem,
    BDropdown,
    BLink,
  },
  data() {
    return {
      checkbox: false,
      fileUrl: serverUri + `/upload/`,
      userId: getUserData().driver_id,
      companyId: getUserData().company_id,
      startDate: "",
      endDate: "",
      perPage: 25,
      selectedVehicle: "",
      vehicle: "",
      pageOptions: [5, 10, 15, 25],
      option: [],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      history_list: [],
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      filter: " ",
      fields: [
        {
          key: "document_type",
          label: "Document Type",
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "expiry_date",
          label: "Expiry Date",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },
        {
          key: "document",
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  props: {},
  methods: {
    deleteFile(id) {
      this.$swal({
        title: "Are you sure to delete this document?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) 
        {
        axios
          .post(`${serverUri}/fleet/deletedocument`, {
            id: id,
            mainId: getUserData().id,
          })
          .then((res) => {
            if (res.data.status) 
            {
              this.getvehicleDetails();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  text: "This document has been deleted successfully.",
                  variant: "success",
                },
              });



            } else {

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error occured",
                  icon: "BellIcon",
                  text: "Sorry! this document is not deleted.",
                  variant: "danger",
                },
              });

            }
          });
        }
      });
    },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getvehicleDetails()
    {
      axios
      .get(
        `${serverUri}/driver/vehicles-details/${router.currentRoute.params.id}`
      )
      .then((res) => {
        this.history_list = res.data;
      });
    }
  },
  created() {
    this.getvehicleDetails()
  },
};
</script>
            
            <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.edit-header {
  display: flex;
  align-items: center;
}
</style><style>
.permission {
  margin: 10px 0;
}

.permission div {
  margin: 2px 0;
}
button.btn.btn-danger {
  margin-left: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f22323;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* .dropdown {
    top: -20px;
  } */
</style>
            